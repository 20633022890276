export const partnershipSections1Mixin = {
  data() {
    return {
      index: 0,
      brands: [
        {
          name: 'Alipay+',
          desc: 'partnership.brand.alipay_plus',
          logo: require('@/assets/brand/AlipayPlus.png'),
          key: 'alipay_plus'
        },
        {
          name: 'WeChat Pay',
          desc: 'partnership.brand.wechat_pay',
          logo: require('@/assets/brand/WeChatPay.png'),
          key: 'wechat_pay'
        },
        {
          name: 'Omise',
          desc: 'partnership.brand.omise',
          logo: require('@/assets/brand/Omise.png'),
          key: 'omise'
        },
        {
          name: 'Smartro',
          desc: 'partnership.brand.smartro',
          logo: require('@/assets/brand/Smartro.png'),
          key: 'smartro'
        },
        {
          name: 'iPay88',
          desc: 'partnership.brand.ipay88',
          logo: require('@/assets/brand/iPay88.png'),
          key: 'ipay88'
        },
        {
          name: 'BC Card',
          desc: 'partnership.brand.bc_card',
          logo: require('@/assets/brand/BC-Card.png'),
          key: 'bc_card'
        },
        {
          name: 'eftPay',
          desc: 'partnership.brand.eft_pay',
          logo: require('@/assets/brand/eftPay.png'),
          key: 'eft_pay'
        },
        {
          name: 'Grab',
          desc: 'partnership.brand.grab',
          logo: require('@/assets/brand/Grab.png'),
          key: 'grab'
        },
        {
          name: 'Komoju',
          desc: 'partnership.brand.komoju',
          logo: require('@/assets/brand/Komoju.png'),
          key: 'komoju'
        },
        {
          name: 'PingPong',
          desc: 'partnership.brand.pingpong',
          logo: require('@/assets/brand/PingPong.png'),
          key: 'pingpong'
        },
        {
          name: 'MDAQ',
          desc: 'partnership.brand.mdaq',
          logo: require('@/assets/brand/M-DAQ.png'),
          key: 'mdaq'
        }
      ]
    };
  },
  computed: {
    selectedBrand() {
      return this.brands[this.index];
    }
  }
};
