<template>
  <div class="section-1">
    <div class="section-1-half-bg"></div>
    <div class="evo-mobile mx-auto text-center d-flex flex-column align-center">
      <div class="evo-title evo-text-66">{{ $t('partnership.title') }}</div>
      <div class="evo-desc evo-text-33 evo-opensans-regular">{{ $t('partnership.desc') }}</div>
      <div class="evo-brand-card d-flex justify-center align-center">
        <v-img :src="selectedBrand.logo"></v-img>
      </div>
      <div class="evo-brand-title evo-text-65">{{ selectedBrand.name }}</div>
      <div class="evo-brand-desc evo-text-33 evo-opensans-regular">{{ $t(selectedBrand.desc) }}</div>
    </div>
    <div class="evo-brand-group">
      <v-slide-group v-model="index" mandatory center-active show-arrows ref="slideGroup">
        <v-slide-item v-for="brand in brands" :key="brand.key" v-slot="{ active, toggle }">
          <div class="brand-item" :class="{ active }" @click="toggle">
            <div class="brand">
              <img loading="lazy" class="evo-img-auto" :src="brand.logo" alt="" />
            </div>
          </div>
        </v-slide-item>
        <template #prev>
          <template v-if="$refs.slideGroup">
            <img loading="lazy" v-show="!$refs.slideGroup.hasPrev" src="@/assets/partnership/long-arrow-left.svg" alt="" />
            <img
              loading="lazy"
              style="transform: rotate(180deg)"
              v-show="$refs.slideGroup.hasPrev"
              src="@/assets/partnership/long-arrow-right.svg"
              alt=""
            />
          </template>
        </template>
        <template #next>
          <template v-if="$refs.slideGroup">
            <img
              loading="lazy"
              style="transform: rotate(180deg)"
              v-show="!$refs.slideGroup.hasNext"
              src="@/assets/partnership/long-arrow-left.svg"
              alt=""
            />
            <img loading="lazy" v-show="$refs.slideGroup.hasNext" src="@/assets/partnership/long-arrow-right.svg" alt="" />
          </template>
        </template>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import { partnershipSections1Mixin } from '@/mixins/partnership.js';
export default {
  name: 'EvoMPartnershipSectionOne',
  mixins: [partnershipSections1Mixin]
};
</script>

<style lang="scss" scoped>
::v-deep(.v-slide-group__prev) {
  position: absolute;
  top: calc(100% + 88px);
  left: 50%;
  transform: translate(calc(-100% - 18px), -50%);
}

::v-deep(.v-slide-group__next) {
  position: absolute;
  top: calc(100% + 88px);
  left: 50%;
  transform: translate(18px, -50%);
}

.section-1-half-bg {
  height: 96vw;
  background-position: left top;
  background-size: 182% !important;
}

.section-1 {
  position: relative;
  color: $header_color;
  background: $header_bg_color;
  padding: 100px 0 148px;

  .evo-mobile {
    position: relative;
  }

  .evo-title {
    line-height: 77px;
    margin-bottom: 30px;
  }

  .evo-desc {
    max-width: 500px;
    line-height: 54px;
    margin-bottom: 120px;
  }

  .evo-brand-card {
    width: 640px;
    height: 517px;
    background: #ffffff;
    box-shadow: 0px 22px 67px 0px rgba(0, 39, 122, 0.18);
    border-radius: 47px;
    margin-bottom: 80px;
  }

  .evo-brand-title {
    margin-bottom: 22px;
    color: $title_color;
    height: 116px;
    font-weight: 600;
    line-height: 60px;
  }

  .evo-brand-desc {
    color: #676767;
    max-width: 670px;
    min-height: 758px;
    line-height: 54px;
  }

  .evo-brand-group {
    position: relative;
    margin-bottom: 148px;
    .brand-item {
      display: flex;
      align-items: center;
      height: 100px;
      margin: 0 55px;
      padding: 0 50px;
      border-radius: 12px;
      background: #ffffff;
      opacity: 0.507;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
      .brand {
        display: flex;
        width: 228px;
      }
    }
  }
}
</style>
