<template>
  <div class="evo-wrapper evo-ph-176 evo-pv-60">
    <div class="section-1-half-bg"></div>
    <!-- head -->
    <div data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" class="evo-title evo-text-51 evo-mb-23 text-center">
      {{ $t('partnership.title') }}
    </div>
    <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" class="evo-desc evo-text-22 evo-opensans-regular text-center">
      {{ $t('partnership.desc') }}
    </div>
    <!-- content -->
    <div data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" class="evo-brand-card">
      <v-img :src="selectedBrand.logo"></v-img>
    </div>
    <div class="evo-brand-title evo-text-30 text-center" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
      {{ selectedBrand.name }}
    </div>
    <v-row class="evo-brand-desc" justify="center" data-aos="fade-up" data-aos-delay="150">
      <v-col cols="9" class="evo-text-15 evo-opensans-regular text-center">
        {{ $t(selectedBrand.desc) }}
      </v-col>
    </v-row>
    <v-row class="evo-brand-group" justify="center">
      <v-col cols="12">
        <v-slide-group v-model="index" mandatory center-active show-arrows ref="slideGroup" data-aos="fade-up" data-aos-delay="200">
          <v-slide-item v-for="brand in brands" :key="brand.key" v-slot="{ active, toggle }">
            <div class="brand-item mx-7" :class="{ active }" @click="toggle">
              <div class="brand">
                <img loading="lazy" class="evo-img-auto" :src="brand.logo" alt="" />
              </div>
            </div>
          </v-slide-item>
          <template #prev>
            <template v-if="$refs.slideGroup">
              <img loading="lazy" v-show="!$refs.slideGroup.hasPrev" src="@/assets/partnership/long-arrow-left.svg" alt="" />
              <img
                loading="lazy"
                style="transform: rotate(180deg)"
                v-show="$refs.slideGroup.hasPrev"
                src="@/assets/partnership/long-arrow-right.svg"
                alt=""
              />
            </template>
          </template>
          <template #next>
            <template v-if="$refs.slideGroup">
              <img
                loading="lazy"
                style="transform: rotate(180deg)"
                v-show="!$refs.slideGroup.hasNext"
                src="@/assets/partnership/long-arrow-left.svg"
                alt=""
              />
              <img loading="lazy" v-show="$refs.slideGroup.hasNext" src="@/assets/partnership/long-arrow-right.svg" alt="" />
            </template>
          </template>
        </v-slide-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { partnershipSections1Mixin } from '@/mixins/partnership.js';
export default {
  name: 'EvoPartnershipSectionOne',
  mixins: [partnershipSections1Mixin]
};
</script>

<style lang="scss" scoped>
::v-deep(.v-slide-group__prev) {
  position: absolute;
  top: calc(100% + 60px);
  left: 50%;
  transform: translate(-100%, -50%);
}

::v-deep(.v-slide-group__next) {
  position: absolute;
  top: calc(100% + 60px);
  left: 50%;
  transform: translate(10px, -50%);
}

.section-1-half-bg {
  background-color: $nav_color;
}

.evo-wrapper {
  position: relative;
  background: $header_bg_color;

  .evo-title,
  .evo-desc {
    position: relative;
    color: $header_color;
  }

  .evo-desc {
    max-width: 459px;
    margin: 0 auto;
  }

  .evo-brand-card {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 343px;
    height: 277px;
    border-radius: 25px;
    box-shadow: 0px 12px 36px 0px rgba(0, 39, 122, 0.18);
    margin: calc(60vh - 284px) auto 36px;
    background-color: #fff;

    .brand {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 240px;
    }
  }

  .evo-brand-title {
    margin-bottom: 28px;
    color: $title_color;
    line-height: 28px;
  }

  .evo-brand-desc {
    margin-left: -176px;
    margin-right: -176px;
    margin-bottom: 28px;
    color: #676767;
    line-height: 25px;
    min-height: 150px;
  }

  .evo-brand-group {
    margin-left: -176px;
    margin-right: -176px;
    margin-bottom: 60px;

    .brand-item {
      display: flex;
      align-items: center;
      padding: 0 23px;
      border-radius: 6px;
      background: #ffffff;
      opacity: 0.507;
      height: 48px;
      cursor: pointer;
      &.active {
        opacity: 1;
      }

      .brand {
        display: flex;
        width: 106px;
      }
    }
  }
}
</style>
