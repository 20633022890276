<template>
  <div>
    <template v-if="!$vuetify.breakpoint.mobile">
      <EvoPartnershipSectionOne />
    </template>
    <template v-else>
      <EvoMPartnershipSectionOne />
    </template>
  </div>
</template>
<script>
import EvoPartnershipSectionOne from '@/components/partnership_sections/Section_1.vue';
import EvoMPartnershipSectionOne from '@/mobile/components/partnership_sections/MSection_1.vue';
export default {
  name: 'EvoPartnership',
  components: {
    EvoPartnershipSectionOne,
    EvoMPartnershipSectionOne
  }
};
</script>
